import { FC, MouseEvent, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { useTranslation } from "localization";
import { IPassportInput } from "types";
import { ImagesGrid } from "./imagesGrid";

import passportIcon from "assets/images/inputs/passport.svg";
import passportGreenIcon from "assets/images/inputs/passport_green.svg";
import fileIcon from "assets/images/inputs/file.svg";
import dangerIcon from "assets/images/danger.svg";

export const PassportImageInput: FC<IPassportInput> = ({
  name,
  label,
  setShowExample,
  setUploadedFiles,
  uploadedFiles,
  passportErrors,
  disabled = false,
  passportImages,
}) => {
  const translation = useTranslation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadedFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const isError = passportErrors && !uploadedFiles?.length;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".webp"],
    },
    disabled: disabled,
  });

  const handleOpenExampleModal = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowExample(true);
  };

  return (
    <div
      className={`text_input_container ${!label ? "remove_gap" : ""} ${
        disabled ? "disable_image_upload" : ""
      }`}
    >
      <label className="input_label passport_label">
        <div>{label}</div>
        <div
          className="example_field"
          onClick={(e) => handleOpenExampleModal(e)}
        >
          <span className="example_text">{translation.example}</span>
          <img
            src={passportIcon}
            alt="Passport"
            className="passport_icon hide_hover"
          />
          <img
            src={passportGreenIcon}
            alt="Passport"
            className="passport_icon show_hover"
          />
        </div>
      </label>
      <div className="input_field">
        <div className="input_filed_content">
          <label
            {...getRootProps()}
            className={`passport_label_input ${
              isDragActive ? "drag_active" : ""
            } ${isError ? "passport_label_input_error" : ""}`}
          >
            {uploadedFiles?.length || passportImages?.length ? (
              <ImagesGrid
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                passportImages={passportImages}
              />
            ) : (
              <div>{translation.upload_image}</div>
            )}

            <img src={fileIcon} alt="File" className="file_icon" />
          </label>
          <input
            {...getInputProps()}
            id={name}
            name={name}
            className={`text_input passport_input ${
              isError ? "input_error" : ""
            }`}
          />
        </div>
        {isError && (
          <div className="error_field">
            <img src={dangerIcon} alt="Danger" className="danger_icon" />
            <p className="error_message">{translation.required}</p>
          </div>
        )}
      </div>
    </div>
  );
};
