import { FC } from "react";

import { useTranslation } from "localization";
import { ITableFilters } from "types";

import noFilterIcon from "assets/images/noFilter.svg";
import noDataIcon from "assets/images/noData.svg";

interface IEmptyPage {
  filters?: ITableFilters;
  message: string;
}

export const EmptyTablePage: FC<IEmptyPage> = ({ filters, message }) => {
  const translation = useTranslation();

  let isFilter: boolean = false;

  for (let key in filters) {
    const filterKey = key as keyof ITableFilters;
    if (Array.isArray(filters[filterKey]) && filters[filterKey]?.length) {
      isFilter = true;
    } else if (filters[filterKey]) {
      isFilter = true;
    }
  }

  return (
    <div className="empty_table_page">
      <div className="image_section">
        <img
          src={isFilter ? noFilterIcon : noDataIcon}
          alt="Filter"
          className="no_filter_icon"
        />
      </div>
      <div className="no_result_match">
        {isFilter ? translation.no_results_match : translation.no_data}
      </div>
      <div className="no_result_description">
        <p className="description">
          {isFilter ? translation.no_item_matched : message}
        </p>
        {isFilter && (
          <p className="description">
            {translation.please_try_with_other_options}
          </p>
        )}
      </div>
    </div>
  );
};
