import { useTranslation } from "localization";
import { InfoElement } from "./InfoElement";
import { useAppSelector } from "reduxState/store";
import { formatDate } from "helpers";

import withdrawIcon from "assets/images/balance/withdraw.svg";
import outcomeIcon from "assets/images/balance/outcome.svg";

export const MobileTable = () => {
  const translation = useTranslation();

  const data = useAppSelector((state) => state.userData.balanceHistory);

  return (
    <div className="mobile_table_container" id="mobile_table_container">
      {data?.map((balance, index) => (
        <div className="trade_mobile_section" key={index}>
          <div className="trade_information">
            <div className="trade_elements_title">
              <InfoElement
                title={translation.amount}
                name={`${balance.amount}`}
              />
              <InfoElement
                title={translation.source}
                name={balance.currency}
                icon={withdrawIcon}
              />
              <InfoElement
                title={translation.type}
                name={balance.type}
                icon={outcomeIcon}
              />
              <InfoElement
                title={translation.date}
                name={formatDate(balance.date)}
              />
            </div>
          </div>
        </div>
      ))}
      <div></div>
    </div>
  );
};
