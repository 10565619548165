import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "reduxState/store";

import { useTranslation } from "localization";
import { InfoElement } from "./InfoElement";
import { MainButton } from "components";
import { TABLE_BUY_SELL } from "constants/table";
import { v4 as uuidv4 } from "uuid";
import { ITradeModalData } from "types";
import { PATHNAMES, USER_TYPES } from "constants/global";
import { setShowVerifyModal } from "reduxState/features/main";
import { CurrencyField } from "./CurrencyField";

import starIcon from "assets/images/starIcon.svg";
import armeniaValuta from "assets/images/armenianCurrency.svg";

interface IMobileTable {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setTradeId: Dispatch<SetStateAction<string>>;
  setTradeData: Dispatch<SetStateAction<ITradeModalData>>;
}

export const MobileTable: FC<IMobileTable> = ({
  setShowModal,
  setTradeId,
  setTradeData,
}) => {
  const trades = useAppSelector((state) => state.trades.tradesData);
  const user = useAppSelector((state) => state.auth.user);

  const isMerchant = user?.role === USER_TYPES.merchant;

  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleBuySell = (
    id: number,
    priceRate: string,
    banks: string[],
    currencyName: string
  ) => {
    if (user?.role === USER_TYPES.user) {
      setShowModal(true);
      setTradeId(`${id}`);
      setTradeData({ priceRate, banks, currencyName });
    } else {
      navigate(PATHNAMES.sign_in);
    }
  };

  const handleShowModal = () => {
    if (user?.role === USER_TYPES.user) {
      navigate(PATHNAMES.sign_in);
    } else {
      dispatch(setShowVerifyModal(true));
    }
  };

  return (
    <div className="mobile_table_container">
      {trades?.map((trade) => (
        <div className="trade_mobile_section" key={uuidv4()}>
          <div className="merchant_section">
            {trade.merchant_rating && (
              <div className="merchant_rate">
                <img src={starIcon} alt="Star" className="star_icon" />
                <span>{`${trade.merchant_rating}/5`}</span>
              </div>
            )}
            <span className="merchant_name">{`${trade.firstName} ${trade.lastName}`}</span>
          </div>
          <div className="underline"></div>
          <div className="trade_information">
            <div className="trade_elements_title">
              <InfoElement
                title={translation.currency}
                name={trade?.currencyTitle}
                fullName={trade?.currencyLongTitle}
                icon={trade?.currencyIcon}
              />
              <InfoElement title={translation.amount} name={trade?.amount} />
              <div className="element_title_section">
                <div className="element_title">{translation.usd}</div>
                <CurrencyField
                  currencyTitle={trade?.currencyTitle}
                  amount={trade?.amount}
                />
              </div>
              <InfoElement
                title={translation.usd_to_amd}
                name={`${trade?.priceRate}`}
                backIcon={armeniaValuta}
              />
              <InfoElement
                title={translation.banks}
                name=""
                isBanks={true}
                banks={trade?.banks}
              />
            </div>
          </div>
          {!isMerchant && (
            <div className="buy_sell_button">
              <MainButton
                name={TABLE_BUY_SELL[trade.type]}
                customClass={`${
                  trade.type === TABLE_BUY_SELL.buy
                    ? "buy_button"
                    : "red_button"
                } ${
                  user && !user?.isVerifiedByAdmin && trade?.forVerifiedUsers
                    ? "disable_state"
                    : ""
                } capitalize`}
                onClick={() =>
                  !user?.isVerifiedByAdmin && trade?.forVerifiedUsers
                    ? handleShowModal()
                    : handleBuySell(
                        trade?.id,
                        trade?.priceRate,
                        trade?.bankIds,
                        trade?.currencyTitle
                      )
                }
              />
            </div>
          )}
        </div>
      ))}
      <div></div>
    </div>
  );
};
